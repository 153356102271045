import React from 'react';
import { withPrefix } from 'gatsby';
import Layout from '../../components/layouts/project';

const IndexPage = () => (
  <Layout
    title="Monkey Spin"
    description="An action-packed vertical side-scroller where the objective is to keep Bananas — the lovable, intrepid monkey — surfing on the vines for as long as possible."
  >
    <div>
      <h1 className="my-12 mx-8 text-center">Monkey Spin</h1>
      <div className="demo py-12 overflow-x-auto bg-gray-900">
        <iframe
          className="monkey-spin border-0 overflow-hidden block"
          src={withPrefix('/MonkeySpin/index.html')}
          allow="autoplay"
        />
      </div>
      <div className="details m-auto py-12 px-8 max-w-2xl">
        <h2>Instructions</h2>
        <p>
          Try to stay on the vines for as long as possible! Collect bananas
          along the way for additional points.
        </p>
        <p>
          <em>
            Note: you may need to click on the game before it will accept
            keyboard input.
          </em>
        </p>
        <table className="controls">
          <thead>
            <tr>
              <th>Key</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Up or Down</td>
              <td>Select a menu option</td>
            </tr>
            <tr>
              <td>Enter</td>
              <td>Choose a menu option or go back</td>
            </tr>
            <tr>
              <td>Left or Right</td>
              <td>Spin</td>
            </tr>
            <tr>
              <td>Space</td>
              <td>Jump</td>
            </tr>
          </tbody>
        </table>

        <h2>Details</h2>
        <p>
          <em>Monkey Spin</em> is a vertical side-scroller where the objective
          is to keep Bananas from falling off the vines for as long as possible.
          It was a collaborative project between myself (programming), Gabe
          Rozenberg (design), Mohsen Hadianfard (project management), Ilyes
          Fourar (art), and Josh Levitt (music).
        </p>
        <p>
          The version as shown here is more of a prototype demonstrating the
          potential of the canvas element. Originally, we wanted to support
          mobile HTML5-capable devices (primarily the iPhone and iPod Touch);
          however, limitations at the time (e.g. CPU/browser bottlenecks)
          prevented this from happening.
        </p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
